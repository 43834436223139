import React from "react";
import "../styles/Landing.css";
import logo from '../Assets/imgs/logo.webp'
import mainimg from '../Assets/imgs/main-img.webp'
import maintext from '../Assets/imgs/main-text.webp'


type Props = {
  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  showPlay: boolean;
  confirmLoader: boolean;
  phoneNo: string;
  onHandlePlay: () => void;
};

const Landing = (props: Props) => {
  return (
    <div className="landing-bg">
       <div className="subscrption-content">
           <div className="logo">
             <img src ={logo} width={258} height={51} alt="Logo"/>
           </div>
           
           <p>Join the</p>
 
           <div className="main-image">
             <img src={maintext} width={320} height={158} alt="Main image"/>
           </div>
 
           <div className="p-text">
           1 Day Free Trail
           </div>

           <div className="sub-text">
           IS APPLICABLE FOR FIRST-TIME Subscribers only further only Rs.12/day! (including Tax)
           </div>
 
 
           <div className="button">
           {props.showError && <p className="error">{props.errorMsg}</p>}
            {props.phoneNo && (
              <>
                {props.showPlay ? (
                  <>
                    {props.confirmLoader ? (
                      <button className="disable-btn p-button">Confirm</button>
                    ) : (
                      <button className="p-button" onClick={() => props.onSubscribePressed()}>
                        Confirm
                      </button>
                    )}
                  </>
                ) : (
                  <button  className="p-button" onClick={props.onHandlePlay}>PLAY NOW</button>
                )}
              </>
            )}
           </div>

          <div className="ptext"> I agree to daily recurring deduction from my mobile blance.Free trail is for new user</div>
          <a className="term" href="terms"> Terms & Condition</a>
 
           
         
           <div className="palyer-img">
           <img src={mainimg} width={320} height={311} alt="Player Image"/>
           </div>
       </div>
    </div>
   );
};

export default Landing;
